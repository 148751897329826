<template>
  <div class="crd_main">
    <div class="crd_main_2">
      <b-button
        :to="{ name: 'new_credentials' }"
        title="Create new credentials"
        variant="success"
        class="mb-4"
      >
        <!-- <font-awesome-icon icon="plus" class="crt_nw_credential_icon" />  -->
        Create new credentials
      </b-button>

      <div
        id="provider_box"
        class="card-layout"
      >
        <b-card
          v-for="credentials in credentialsList"
          :key="credentials.id"
          :title="credentials.name"
        >
          <template v-slot:header>
            <app-provider-header :provider="credentials.provider" />
          </template>

          <template v-slot:footer>
            <b-button
              :to="{ name: 'credentials', params: { credentialsId: credentials.id } }"
              title="Edit this credentials"
              variant="primary"
              class="mr-1"
            >
              <font-awesome-icon icon="edit" />
            </b-button>
            <b-button
              title="Delete this credentials"
              variant="danger"
              @click="deleteCredentials(credentials)"
            >
              <font-awesome-icon :icon="['far', 'trash-alt']" />
            </b-button>
          </template>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>

  import { getCredentialsList, deleteCredentials } from '@/shared/api/credentials-api';

  import { AppProviderHeader } from '@/shared/components';
  import { displayConfirmDialog, displayNotification } from '@/shared/services/modal-service';

  export default {
    name: 'Credentials',

    components: {
      AppProviderHeader,
    },

    data: function data() {
      return {
        credentialsList: [],
      };
    },

    async created() {
      this.credentialsList = await getCredentialsList();
    },

    methods: {
      async deleteCredentials(credentials) {
        const message = 'This will delete the credentials. Continue?';
        const confirm = await displayConfirmDialog(this, { title: 'Delete Request', message });
        if (confirm) {
          try {
            await deleteCredentials(credentials);
            displayNotification(this, { message: 'Credentials deleted.', variant: 'info' });

            const index = this.credentialsList.findIndex((cred) => cred.id === credentials.id);
            this.credentialsList.splice(index, 1);
          } catch (e) {
            displayNotification(this, { message: 'Unable to delete credentials.', variant: 'danger' });
          }
        }
      },
    },
  };
</script>

<style scoped>
.card-layout {
  display: flex;
  flex-wrap: wrap;
}

.card {
  width: 350px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.card-header {
  padding: 0;
}

.card-title {
  margin: 0;
}

.card-footer {
  display: flex;
  justify-content: space-between;

}
.mb-4 {
  border-radius: 3rem;
  color: white;
  overflow: hidden;
  padding: 5px 10px;
  transition: .10s transform ease-in-out;
  will-change: transform;
  z-index: 0;
  background-color: #065090;
  margin-top: 10px;
  margin-left: 10px;
}
.mb-4::after{
background-color: #ECAA0D;
border-radius: 5rem;
content: '';
display: block;
height: 100%;
width: 100%;
position: absolute;
left: 0;
top: 0;
transform: translate(-100%, 0) rotate(10deg);
transform-origin: top left;
transition: 0.3s transform ease-out;
will-change: transform;
z-index: -1;
}

.mb-4:hover::after{
transform: translate(0, 0);
}
.mb-4:hover{
color: white;
transform :scale(1.05);
will-change: transform;
}

.crt_nw_credential_icon {
  color: white;
}
.crd_main{
  width: 100%;
  padding: 0px 0px;
  min-height: 400px;
}
.crd_main_2{
  width: 90%;
  margin: auto;
  box-shadow: 0px 6px 25px rgba(6, 80, 144, 0.25);
  border-radius: 12px;
  border: 1px solid #dadce0;
  min-height: 400px;
  margin-bottom: 20px;
}
#provider_box{
  margin-top: 15px;
  margin-left: 10px;
}
@media only screen and (max-width: 480px) {
  .crd_main_2{
    width: 100%;
    margin: auto;
    box-shadow: none;
    border-radius: 0%;
    border: none;
    }

  }
  @media only screen and (max-width: 768px) {
  .crd_main_2{
    width: 100%;
    margin: auto;
    box-shadow: none;
    border-radius: 0%;
    border: none;
    }

  }
</style>

<template>
  <div class="new_crd_card_main">
    <div
      v-if="credentials.provider == null"
      class="card_main"
    >
      <h1>Choose Terraform provider</h1>
      <!-- <b-card-group columns>
        <a
          v-for="provider in providerList"
          v-if="provider !== ''"
          :key="provider"
          @click="credentials.provider = provider"
        >
          <b-card
            header-class="provider-card"
            no-body
          >
            <template slot="header">
              <app-provider-header
                :provider="provider"
                style="width: 100%;"
              />
            </template>
          </b-card>
        </a>
      </b-card-group> -->
      <b-card-group columns>
        <a
          v-if="providerList.length > 0"
          @click="credentials.provider = providerList[0]"
        >
          <b-card
            header-class="provider-card"
            no-body
          >
            <template slot="header">
              <app-provider-header
                :provider="providerList[0]"
                style="width: 100%;"
              />
            </template>
          </b-card>
        </a>
      </b-card-group>
    </div>
    <app-credentials
      v-if="credentials.provider != null"
      :credentials="credentials"
    />
  </div>
</template>

<script>
  import { AppProviderHeader } from '@/shared/components';
  import AppCredentials from '@/pages/credentials/credentials.vue';
  import { getProviders } from '@/shared/api/credentials-api';

  export default {
    name: 'NewCredentials',
    components: {
      AppProviderHeader,
      AppCredentials,
    },
    data: () => (
      {
        credentials: {
          provider: 'aws',
        },
        providerList: [],
      }
    ),
    async created() {
      this.providerList = await getProviders();
    },
  };
</script>

<style>
.card-header {
  display: flex;
  align-items: center;
}

a {
  cursor: pointer;
}

.provider-card {
  padding: 0;
}
</style>
